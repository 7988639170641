<template>
  <div class="selectClass">
    <!-- 头部导航 -->
    <van-nav-bar
      class="navBar"
      fixed
      :placeholder="true"
      left-arrow
      @click-left="$router.go(-1)"
    >
      <template #title>
        <span>我的课程</span>
      </template>
    </van-nav-bar>
    <div class="list">
      <div class="item" v-for="(course, key) in ActiveList" :key="key" @click="setDetails(course)">
        <div class="left">
          <img :src="course.coverPath" class="img" />
          <div class="mark">热卖</div>
        </div>
        <div class="right">
          <div class="title">{{ course.packageName }}</div>
          <div class="text">
            <div class="question_cirle-continer">
                <div class="question_cirle-yes" :style="setCirleNum(course)"></div>
            </div> 
            <div>{{course.studyCourseCount}}/{{course.classHourCount1}}课时</div>

          </div>
          <div class="mark">
            <div class="mark_title">有效期至:{{ course.effectiveEtime }}</div>
            <div class="mark_study">继续学习</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { coures ,courseList} from "@/api/study_course.js";
export default {
  data() {
    return {
      ActiveList: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    setCirleNum(item){
      let num = item.studyCourseCount/item.classHourCount1*100 + "%";
      return {
        "width":num
      }
    },
    setDetails(item){
        let id = item.id
        this.$router.push({path: '/meallist/'+ id+ '/mycourse'})
        // this.$router.push({
        //   path:"/meallist",
        //   query:{
        //     id:item.id
        //   }
        // })
       
    },
    init() {
      coures().then((res) => {
        this.ActiveList = res.data.data;
      });
    },

    onRefresh(type) {
      console.log("下拉刷新type---", type);
    },
  },
};
</script>

<style lang="less" scoped>
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.selectClass {
  height: 100vh;
  font-family: PingFangSC-Medium, PingFang SC;
  position: relative;
  // background-color: pink;
  .list {
    padding: 0px 12px;
    .item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 14px 0;
      border-bottom: 1px solid #dddddd;
      &:last-child {
        border: none;
      }
      .left {
        width: 109px;
        position: relative;
        margin-right: 9px;
        border-radius: 5px; /*no*/
        .img {
          width: 100%;
          border-radius: 5px; /*no*/
        }
        .mark {
          opacity: 0.6;
          position: absolute;
          left: 0;
          top: 0;
          background: #000000;
          border-radius: 5px 0px 5px 0px; /*no*/

          font-size: 11px; /*no*/
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          padding: 2px 4px;
          
        }
      }
      .right {
        width: calc(100% - 118px);
        .title {
          font-size: 15px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 550;
          color: #333333;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-wrap: break-word;
        }
        .text {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-wrap: break-word;
          margin: 6px 0 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .question_cirle-continer{
                width:calc(100% - 63px);
                height: 10px;
                background: #E7ECFF;
                border-radius: 5px;
                .question_cirle-yes{
                    width:50%;
                    height: 10px;
                    background: #5F7DFF;
                    border-radius: 5px;
                }
           }
        }
        .mark {
          font-size: 13px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 550;
          line-height: 18px;
          display: flex;
          justify-content: space-between;
          .mark_title{
            font-size: 12px;
            color: #cccccc;
          }
          .mark_study{
            color: #5F7DFF;
          }
        }
      }
    }
  }
}
</style>

